import modular from "modujs";
import * as modules from "./_modules";
// import Facturation from './Facturation';

const app = new modular({
    modules: modules,
});

function init() {
    app.init(app);
}

window.addEventListener('load', event => {
	init();
	// new Facturation();
});

/*
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */
$(document).ready(function() {
    searchProducts($('#proshopSearchProductsHome'));

	// implémente la fonctionnalité de récupération de la facture d'une commande
	new Facturation();

	// choix de l'établissement
	$(document).on('click', '.multi__choice-btn', function(e) {
		e.preventDefault();
		$('.multi-overlay-container').removeClass('hide');
	});
	$(document).on('click', '.overlay-close', function(e) {
		e.preventDefault();
		$(this).parent().parent().addClass('hide');
	});
	$(document).on('click', '.no-btn', function(e) {
		e.preventDefault();
		$(this).parent().parent().parent().addClass('hide');
	});
	$(document).on('click', '.logout-confirm', function(e) {
		if ($('.cart-block .big-button .ajax_cart_no_product').hasClass('unvisible')) {
			e.preventDefault();
			var href = $(this).attr('href');
			$('.multi-logout-overlay-container').removeClass('hide');
			$('.multi-logout-overlay-container').find('.yes-btn').attr('href', href);
		}
	});

	// Affiche le champ numéro client seulement si l'utilisateur est déjà client dans le formulaire d'inscription
	// $('#nutrisens_customer').on('change', function() {
    //     // console.log($(this).val());
	// 	var select_value = $(this).val();
	// 	var hide_el = $('.form_group_external_customer_id');
	// 	// console.log(hide_el);
	// 	if (select_value == "1" || select_value == 1) {
	// 		hide_el.removeClass("hide");
	// 	} else {
	// 		hide_el.addClass("hide");
	// 		hide_el.find('input[type="text"]').val("");
	// 	}
    // });

	/**
	 * Fixe le bouton ajouter au panier lors du scroll 
	 */ 
	if ($('.add-to-cart-button').length ) {
		let $button_position = $('.add-to-cart-button').offset().top + $('.add-to-cart-button').outerHeight();
		$(window).bind('scroll', function() {
			// console.log('$(window).scrollTop(', $(window).scrollTop());
			// console.log("$('.add-to-cart-button').offset().top",$('.add-to-cart-button').offset().top);
			// console.log(" ('.add-to-cart-button').outerHeight()",  $('.add-to-cart-button').outerHeight());
			// console.log( $('.add-to-cart-button').offset().top + $('.add-to-cart-button').outerHeight());
			if($(window).scrollTop() >= $button_position) {
				$('.add-to-cart-container').addClass('fixed-add-to-cart-button');

			} else {
				$('.add-to-cart-container').removeClass('fixed-add-to-cart-button');
			}
		})
	}

	$(document).on('click', '.add-to-cart-button', function(e) {
	
		e.preventDefault();
		addProductsToCart();
	});

	/**
	 * Construit le tableau de formulaire permettant par la suite l'ajout au panier des produits
	 */
	function addProductsToCart()
	{
		let forms_list = [];
		let $list_products_pro = $('.product-list-pro');
		if ($list_products_pro.length) {
			$('.add-to-cart-overlay').removeClass('hide');
			// Récupération des formulaires
			let $cart_forms = $list_products_pro.find('.add-product-to-cart-form');
			
			if ($cart_forms.length) {
			$cart_forms.each(function() {
				let $this = $(this);

				let $quantity = $this.find('.product-quantity-wanted-input');

				// Si la quantité est définie on prépare l'ajout au panier
				if ($quantity.length && $quantity.val() && parseInt($quantity.val()) > 0) {
				forms_list.push($this);
				}
			});
			}
		}

		if (forms_list) {
			$('.cart-progress-container .progress-bar').css(
			'width',
			0
			);
			submitAddToCartForms(forms_list, forms_list.length);
		}
	}

		/**
	 * Fonction récursive de soumission des formulaires pour l'ajout au panier des produits
	 * @param forms_list
	 * @param products_count
	 */
	function submitAddToCartForms(forms_list, products_count) {
	if (forms_list.length) {
		var $first_form = forms_list.shift();

		if ($first_form) {
			var query = $first_form.serialize() + '&token=' + static_token;
			var actionURL = $first_form.attr('action');
			// console.log(query)
			// query = 'controller=cart&add=1&ajax=true&qty=1&id_product=247&token=' + static_token ;
			// console.log(query)
			// On soumet les données du formulaire pour ajouter le produit au panier
			$.ajax({
				type: 'POST',
				headers: {"cache-control": "no-cache"},
				url: actionURL,
				async: true,
				cache: false,
				data: query,
				dataType: 'json',
				success: function (response)
				{
					if (response) {
						// Calcul de la progression en fonction du nombre de produits déjà ajoutés au panier
						var products_added_rate = parseInt(((products_count - forms_list.length) / products_count) * 100);
						$('.cart-progress-container .progress-bar').css(
						'width',
						products_added_rate + '%'
						);
						submitAddToCartForms(forms_list, products_count);
					}
				},
				error: function (result) {
					console.log(result)
				}
			})
		}
	} else {
		// Le traitement des formulaires est terminé on peut donc remettre à 0 la barre de progression
		$('.cart-progress-container .progress-bar').css(
		'width',
		0
		);
		$('.add-to-cart-overlay').addClass('hide');

		// Remise à zéro des quantités produits à ajouter au panier
		let $products_list = $('.product-list-pro');
		if ($products_list.length) {
		$products_list.find('.product-quantity-wanted-input').each(function (){
			$(this).val(0);
		});
		}

		// Mise à jour de la modal de récap panier
		// createModalAndDropdown(0, 0);

		// Mise à jour de l'icône de panier à partir du script leofeature_cart.js du module leofeature
		// console.log(ajaxCart);
		ajaxCart.refresh();

		/**
		 * Prestashop 1.7 
		 */
		// var refresh_url = $('.shopping_cart a').data('refresh-url');
		// console.log()
		// $.ajax({
		// type: 'POST',
		// headers: {"cache-control": "no-cache"},
		// url: refresh_url,
		// async: true,
		// cache: false,
		// success: function (resp)
		// {
		// 	// On remplace le bloc HTML complet représentant l'icône du panier
		// 	$('.leo-blockcart').replaceWith($(resp.preview).find('.blockcart'));
		// 	$(".blockcart.cart-preview").addClass('leo-blockcart').removeClass('blockcart');
		// },
		// error: function (XMLHttpRequest, textStatus, errorThrown) {}
		// });
	}
	}

    $(document).on('submit', '#proshopSearchProductsHome', function(e) {
        e.preventDefault();
        searchProducts($(this));
    });

    $(document).on('click', '.reset-search-form', function() {
        $('#searchName').val('');
        $('#searchReference').val('');
        searchProducts($('#proshopSearchProductsHome'));
    });

	$('.order-recap-container .view-order-details').click(function (){
		// console.log('Lien détails commande', $(this).attr('href'));
		$.ajax({
			"url": $(this).attr('href'),
			"data": {
				"ajax": true,
			},
			"success": function (data) {
				if (data && data.length) {
					$('.order-details-block').html(data);
					$('.order-details-block').removeClass('hide');
					$('.order-details-block-close').removeClass('hide');
				}
			}
		});
		return false;
	});

	/**
	 * Update product's quantity customer wants to order
	 */
	$(document).on('click', '.product-quantity-actions .update-cart-button', function() {
		if ($(this).hasClass('update-cart-button-disabled')) {
			return false;
		}

		var $quantity_container = $(this).parents('.product-quantity-actions').first();
		var $quantity_input = $quantity_container.find('.product-quantity-wanted-input').first();
		var current_quantity = parseInt($quantity_input.val());

		if (!current_quantity) {
			current_quantity = 0;
		}
		// Decrease current quantity
		if ($(this).hasClass('cart-quantity-minus-button')) {
			if (current_quantity == 0) {
				return false;
			}
			if ((current_quantity - 1) >= 0) {
				$quantity_input.val(parseInt(current_quantity - 1));
			}
		}
		// Increase current quantity
		if ($(this).hasClass('cart-quantity-plus-button')) {
			$quantity_input.val(parseInt(current_quantity + 1));
		}
		return false;
	});

	$(document).on('change keyup', '.product-quantity-wanted-input', function () {
		var pattern = /[^0-9]/;
		var current_quantity = $(this).val();
		if (current_quantity.match(pattern)) {
			$(this).val(0);
			return false;
		}
		var available_quantity = 0;

		$(this).val(available_quantity);
		return false;
	});

	$(document).on('click', '.product-add-to-cart-button', function() {
		var product_id = parseInt($(this).attr('data-product-id'));

		if (product_id > 0) {
			var quantity = 0;
			var $quantity_to_add = $('.product-quantity-wanted-input[data-product-id="' + product_id + '"]');
			if ($quantity_to_add.length) {
				quantity = parseInt($quantity_to_add.val());
				if (quantity > 0) {
					addProductToCart(product_id, quantity);
				} else {
					if ($('.invalid-product-quantity-popup').length) {
						$('.invalid-product-quantity-popup').removeClass('hide');
					}
				}
			}
		}
		return false;
	});


	$(document).on('click', '.order-details-block-close', function() {
		var $order_block_container = $(this).parents('.order-details-container');
		if ($order_block_container.length) {
			if ($order_block_container.find('.order-details-block').length) {
				$order_block_container.find('.order-details-block').addClass('hide');
				$order_block_container.find('.order-details-block').html('');
				$(this).addClass('hide');
			}
		}
		return false;
	});

	/**
	 * Close invalid quantity popup error
	 */
	$(document).on('click', '.invalid-product-quantity-close', function() {
		var $popup = $(this).parents('.invalid-product-quantity-popup');
		if ($popup) {
			$popup.addClass('hide');
		}
		return false;
	});

	var disabled_dates = [];
	if ($('#delivery-non-worked-days').length && $('#delivery-non-worked-days').val()) {
		disabled_dates = JSON.parse($('#delivery-non-worked-days').val());
	}
	var opened_days = [];
	if ($('#delivery-opened-days').length && $('#delivery-opened-days').val()) {
		opened_days = JSON.parse($('#delivery-opened-days').val());
	}

	var delivery_delay = 1;
	if ($("#delivery-delay").length && $("#delivery-delay").val()) {
		delivery_delay = parseInt($("#delivery-delay").val());
	}

	let minimumDeliveryDate = new Date();
    if ($("#minimum-delivery-date").length && $("#minimum-delivery-date").val()) {
        minimumDeliveryDate = $("#minimum-delivery-date").val();
    }

	// Expédition le lendemain de la commande
	delivery_delay++;

	if ($('#delivery-date-choice-input').length) {
		$('#delivery-date-choice-input').datepicker({
			beforeShowDay: disableSpecificDates,
			changeYear: true,
			minDate: minimumDeliveryDate,
			maxDate: '+2M',
			dateFormat: 'dd-mm-yy'
		});
	}

	function disableSpecificDates(date) {
		var m = date.getMonth();
		var d = date.getDate();
		var y = date.getFullYear();

		// First convert the date in to the mm-dd-yyyy format
		// Take note that we will increment the month count by 1
		var current_month = (m + 1);
		var currentdate = (current_month < 10 ? '0' + current_month : current_month) + '-' + (d < 10 ? '0' + d : d) + '-' + y ;

		// We will now check if the date belongs to disableddates array
		if ($.inArray(currentdate, disabled_dates) != -1 ) {
			return [false];
		}
		var day = date.getDay();
		if ($.inArray(day, opened_days) == -1) {
			return [false];
		}
		// In case the date is not present in disabled array, we will now check if it is a weekend.
		// We will use the noWeekends function
		var weekenddate = $.datepicker.noWeekends(date);
		return weekenddate;
	}

	$(document).on('click', '#cgv', function (){
		if ($(this).prop('checked')) {
			$('.cgv-accept-error').addClass('hide');
		}
	});

	$(document).on('blur, change', '#delivery-date-choice-input', function (){
		if ($(this).val()) {
			$('.delivery-date-wanted-error').addClass('hide');
		}
	});

	$(document).on('click', '.pro-payment-validation', function(e) {
		e.preventDefault();
		validateProOrder();
		return false;
	});

	$('.professionnal-auth .auth-radio-button').click(function() {
		if (!$(this).hasClass('active')) {
			$('.professionnal-auth .auth-radio-button').each(function() {
				$(this).removeClass('active');
			});
			$(this).addClass('active');
			var $label_to_display;
			$('.account-creation-container').find('a').each(function() {
				$(this).css('display', 'none');
			});
			if ($('.' + $(this).attr('data-label')).length) {
				$label_to_display = $('.' + $(this).attr('data-label'));
				$label_to_display.css('display', 'inline-block');
			}
		}
	});

	$(document).on('submit', '.professionnal-auth-form', function(e) {
		var result = validateCustomerCreationForm();
		if (!result) {
			$('.form-errors-container').removeClass('hide');
			e.preventDefault();
		} else {
			$('.form-errors-container').addClass('hide');
		}
	});

	function displayHTMLForm(form_type) {
		var url = '';
		if (form_type == 'online_account_form') {
			url = $('.professionnal-auth').find('a.account-creation').attr('href');
		} else if (form_type == 'customer_account_request_form') {
			url = $('.professionnal-auth').find('a.customer-account-request').attr('href');
		}
		if (url) {
			$.ajax({
				url: url,
				type: 'POST',
				data: {
					'method': 'display_form',
					'display_form': form_type,
					'ajax': true,
				},
				error: function () {},
				success: function(data) {
					var response = JSON.parse(data);
					if(response.error) {

					} else {
						if (response.result) {
							console.log('Result OK');
							if (response.result.template && $('.professionnal-auth').length) {
								console.log('Template found');
								$('.professionnal-auth').html(response.result.template);
							}
						}
					}
				}
			});
		}

	}

	function submitOnlineAccountForm() {

		var url = $('#online_account_creation_form').attr('action');
		$.ajax({
			url: url,
			type: 'POST',
			data: {
				'method': 'test',
				'ajax': true,
			},
			error: function () {},
			success: function(data) {
				var response = JSON.parse(data);
				console.log(response);
			}
		});

	}

	function submitCustomerAccountRequestForm() {

		var url = $('#customer_account_creation_request_form').attr('action');
		$.ajax({
			url: url,
			type: 'POST',
			data: {
				'method': 'test',
				'ajax': true,
			},
			error: function () {},
			success: function(data) {
				var response = JSON.parse(data);
				console.log(response);
			}
		});

	}

	/**
	 * Fonction de vérification de la page de paiement pour les pro
	 */
	function validateProOrder() {
		// Acceptation des CGV
		var cgv = false;
		// Date de livraison souhaitée
		var delivery_date_choice = '';
		// Numéro d'engagement ou service concerné
		var service_order = '';

		// Vérification de l'acceptation des CGV
		if ($('#cgv').length) {
			cgv = $('#cgv').prop('checked');
		}
		// Vérification de la saisie d'une date de livraison souhaitée
		if ($('#delivery-date-choice-input').length) {
			delivery_date_choice = $('#delivery-date-choice-input').val();
		}
		// Vérification de la saisie du numéro d'engagement/service concerné
		if ($('.service-order-input').length) {
			service_order = $('.service-order-input').val();
		}

		// Si tous les champs obligatoires sont renseignés on peut valider la commande
		if (delivery_date_choice && cgv === true && service_order) {
			$('.pro-order-form').submit();
		} else {
			// Sinon on affiche les différentes erreurs relevées
			if (!delivery_date_choice) {
				$('.delivery-date-wanted-error').removeClass('hide');
			}
			if (!service_order) {
				$('.service-order-error').removeClass('hide');
			}
			if (!cgv) {
				$('.cgv-accept-error').removeClass('hide');
			}
		}

		return false;
	}

	/**
	 * Add given product's quantity to cart
	 *
	 * @param {*} product_id
	 * @param {*} quantity
	 */
	function addProductToCart(product_id, quantity)
	{
		ajaxCart.add(product_id , null ,false , null , quantity , null);
	}

	/**
	 * Remove specified product from current cart
	 *
	 * @param {*} product_id
	 * @param {*} product_attribute_id
	 */
	function removeProductFromCart(product_id, product_attribute_id)
	{
		ajaxCart.remove(product_id, product_attribute_id, null, null);
	}

	function validateCustomerCreationForm()
	{
		var fields_ok = true;
		var $form = $('.professionnal-auth-form');
		if ($form.length) {
			if ($form.find('.is_required').length) {
				$form.find('.is_required').each(function() {
					if (!$(this).val()) {
						fields_ok = false;
						$(this).addClass('form-field-error');
					} else {
						$(this).removeClass('form-field-error');
					}
				});
			}
			
			// var $nutrisens_customer = $form.find('#nutrisens_customer');

			// if ($nutrisens_customer.length) {
			//     var $selected_option = $nutrisens_customer.find('option:selected');

			//     if ($selected_option.length) {
            //         if ($selected_option.val() !== "1" && $selected_option.val() !== "0") {
            //             fields_ok = false;
            //             $nutrisens_customer.parents('.selector').addClass('form-field-error');
            //         } else {
            //             $nutrisens_customer.parents('.selector').removeClass('form-field-error');
            //         }
            //     } else {
			//         fields_ok = false;
            //         $nutrisens_customer.parents('.selector').addClass('form-field-error');
            //     }
            // }
		}
		return fields_ok;
	}

	// Ouverture CGV dans une iframe
    $(".order-pro-order-info a.iframe").fancybox({
        'type': 'iframe',
        'width': 600,
        'height': 600
    });
});

function searchProducts($form) {
    $.ajax({
        type: "POST",
        url: $form.attr('action'),
        data: $form.serialize(),
        beforeSend: function () {
            $('.loader-overlay-container').removeClass('hide');
        }
    }).done(function (responseText) {
        if (responseText) {
            $('.products-list-container').html(responseText);
        }
        $('.loader-overlay-container').addClass('hide');
    });
}
